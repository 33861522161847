//
// footer.scss
//


.footer {
    padding: 48px 0px;
    background: #0082BD;
    background-position: center center;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;

    .reset-padding {
        padding: 0;
        display: flex;
        justify-content: center;
        // margin-top: -30px;
    }

    .footer-content {
        display: 'flex';
        justify-content: 'space-between';
    }

    .copy-right {
        display: flex;
        color: white;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .footer-sub-menu {
        li {
            padding: 6px 0;

            .footer-link {
                color: rgba($white, 0.6);
                transition: all 0.5s;

                &:hover {
                    color: $white;
                    margin-left: 5px;
                }
            }
        }
    }
}
